import Image from "next/image";

const FULL_RATIO = 1567 / 326;
const CONDENSED_RATIO = 150 / 150;

export interface LogoProps {
  width: number;
  className?: string;
  style?: "full" | "condensed";
}

export function Logo({ width, style = "full", className }: LogoProps) {
  return (
    <picture>
      <source
        srcSet={
          style === "full"
            ? "/images/logo-dark.png"
            : "/images/logo-condensed-dark.svg"
        }
        media="(prefers-color-scheme: dark)"
      />
      <Image
        src={
          style === "full" ? "/images/logo.webp" : "/images/logo-condensed.svg"
        }
        width={width}
        height={Math.round(
          width / (style === "full" ? FULL_RATIO : CONDENSED_RATIO)
        )}
        alt="Vetsie.ai logo"
        className={className}
      />
    </picture>
  );
}
