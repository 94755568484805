"use client";

import clsx from "clsx";
import React, {
  FormEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import * as yup from "yup";
import { getTextClasses } from "./text";
import { useTheme } from "./theme-provider";
import { UserError } from "./user-error";

export interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  variant?: "primary" | "secondary";
  size?: "sm" | "md" | "lg";
  loading?: boolean;
  theme?: "classic" | "modern";
}

export function getButtonColorClasses({
  disabled,
  loading,
  variant = "primary",
  theme = "classic",
}: ButtonProps) {
  return clsx(
    "transition duration-200",
    theme === "classic" &&
      clsx(
        variant === "primary" &&
          clsx(
            "bg-downriver-950 text-white",
            "hover:bg-downriver-900 focus:bg-downriver-900 active:bg-downriver-800",
            "dark:bg-malibu-500 dark:text-malibu-950",
            "dark:hover:bg-malibu-400 dark:focus:bg-malibu-400 dark:active:bg-malibu-600",
            "focus:outline-none focus:ring focus:ring-downriver-100",
            "active:ring active:ring-downriver-200"
          ),
        variant === "secondary" &&
          clsx(
            "border bg-transparent",
            "border-downriver-950 text-downriver-950",
            "dark:border-malibu-500 dark:text-malibu-500",
            "hover:bg-downriver-50 focus:bg-downriver-50 active:bg-downriver-100",
            "dark:hover:bg-malibu-950 dark:focus:bg-malibu-950 dark:active:bg-malibu-900",
            "focus:outline-none focus:ring focus:ring-downriver-100",
            "active:ring active:ring-downriver-200"
          )
      ),
    theme === "modern" &&
      clsx(
        variant === "primary" &&
          clsx(
            "bg-downriver-950 text-white",
            "hover:bg-downriver-900 focus:bg-downriver-900 active:bg-downriver-800",
            "dark:bg-malibu-500 dark:text-malibu-950",
            "dark:hover:bg-malibu-400 dark:focus:bg-malibu-400 dark:active:bg-malibu-600",
            "focus:outline-none focus:ring focus:ring-downriver-100",
            "active:ring active:ring-downriver-200"
          ),
        variant === "secondary" &&
          clsx(
            "border bg-transparent",
            "border-downriver-950 text-downriver-950",
            "dark:border-lime-200 dark:text-lime-200",
            "hover:bg-downriver-50 focus:bg-downriver-50 active:bg-downriver-100",
            "dark:hover:bg-lime-200 dark:focus:bg-lime-200 dark:active:bg-lime-500",
            "dark:hover:text-blue-950 dark:focus:text-blue-950 dark:active:text-blue-950",
            "focus:outline-none focus:ring focus:ring-downriver-100",
            "active:ring active:ring-downriver-200"
          )
      ),
    (disabled || loading) && "opacity-50 cursor-not-allowed"
  );
}

export function getButtonClasses({
  size = "md",
  variant = "primary",
  loading = false,
  theme = "classic",
  disabled,
  className,
}: ButtonProps) {
  return clsx(
    getButtonColorClasses({ disabled, loading, variant, theme }),
    getTextClasses({ size }),
    size === "sm" && "px-4 py-2",
    size === "md" && "px-6 py-3",
    size === "lg" && "px-6 py-3",
    "rounded-md font-semibold",
    "transition-colors duration-100",
    "flex items-center justify-center",
    (disabled || loading) && "opacity-50 cursor-not-allowed",
    className
  );
}

export function Button({
  size = "md",
  loading = false,
  variant = "primary",
  className,
  disabled,
  children,
  ...props
}: ButtonProps) {
  const [theme] = useTheme();

  return (
    <button
      className={getButtonClasses({
        size,
        variant,
        disabled,
        loading,
        className,
        theme,
      })}
      disabled={disabled || loading}
      {...props}
    >
      {loading && (
        <svg
          className="animate-spin h-5 w-5 mr-3"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {children}
    </button>
  );
}

export interface InputProps extends React.ComponentPropsWithoutRef<"input"> {
  error?: string;
  textSize?: "sm" | "md" | "lg";
  autosize?: boolean;
}

export function getInputColorClasses({ error }: { error?: string }) {
  return clsx(
    "bg-tuft-bush-50 text-black/70",
    "dark:bg-gray-700/70 dark:text-white/70",
    "placeholder:text-black/40",
    "dark:placeholder:text-white/70",
    "focus:outline-none focus:ring focus:bg-transparent",
    "focus:ring-downriver-100",
    "dark:focus:ring-downriver-100",
    error && "outline-rose-600 dark:outline-rose-600 bg-rose-200"
  );
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function (
  { error, className, autosize, textSize = "lg", type, value, ...props },
  ref
) {
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({ ...inputRef.current! }));

  useEffect(() => {
    if (!inputRef.current || !autosize) {
      return;
    }

    inputRef.current.style.width = "0px";
    inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
  }, [autosize, value]);

  return (
    <input
      className={clsx(
        "max-w-full w-full rounded",
        "p-3",
        "content-center",
        getInputColorClasses({ error }),
        getTextClasses({ size: textSize }),
        error && "border outline outline-2 -outline-offset-2",
        className
      )}
      type={type}
      ref={inputRef}
      value={value}
      {...props}
    />
  );
});
Input.displayName = "Input";

export interface TextareaProps
  extends React.ComponentPropsWithoutRef<"textarea"> {
  error?: string;
  textSize?: "sm" | "md" | "lg";
  autosize?: boolean;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { error, className, textSize = "lg", autosize = false, onInput, ...props },
    ref
  ) => {
    const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (!autosize) {
        return;
      }

      e.currentTarget.style.height = "0px";
      e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;

      onInput?.(e);
    };

    return (
      <textarea
        ref={ref}
        className={clsx(
          getInputColorClasses({ error }),
          "px-3 py-2 rounded w-full",
          getTextClasses({ size: textSize }),
          error && "border outline outline-2 -outline-offset-2",
          className
        )}
        onInput={handleInput}
        {...props}
      />
    );
  }
);
Textarea.displayName = "Textarea";

export interface LabelProps extends React.ComponentPropsWithoutRef<"label"> {
  textSize?: "sm" | "md" | "lg";
}

export function Label({ className, textSize = "md", ...props }: LabelProps) {
  return (
    <label
      className={clsx(
        getTextClasses({ size: textSize }),
        "font-semibold block mb-2",
        className
      )}
      {...props}
    />
  );
}

export interface FormProps extends React.ComponentPropsWithoutRef<"form"> {}

export const Form = forwardRef<HTMLFormElement, FormProps>(
  ({ className, ...props }, ref) => {
    return (
      <form
        ref={ref}
        className={clsx("flex flex-col gap-6", className)}
        {...props}
      />
    );
  }
);
Form.displayName = "Form";

export interface FieldErrorProps extends React.ComponentPropsWithoutRef<"p"> {
  error?: string;
}

function markdownToHtmlLinks(input: string): string {
  const regex = /\[(.*?)\]\((.*?)\)/g;
  return input.replace(
    regex,
    `<a class="${clsx(
      "text-rose-600 dark:text-rose-300 underline",
      "hover:text-rose-500 hover:dark:text-rose-400",
      "active:text-rose-400 active:dark:text-rose-500"
    )}" href="$2">$1</a>`
  );
}

export function FieldError({ error, className, ...props }: FieldErrorProps) {
  if (!error) {
    return null;
  }

  return (
    <p
      className={clsx(
        "text-rose-600 dark:text-rose-300 mt-1",
        "transition-opacity duration-500",
        getTextClasses({ size: "sm" }),
        className
      )}
      {...props}
      dangerouslySetInnerHTML={{ __html: markdownToHtmlLinks(error) }}
    />
  );
}

export type FormErrors<T> = {
  [P in keyof T]?: string;
} & {
  root?: string;
};

export class FormError extends Error {
  constructor(message: string, public formattedMessage: string = message) {
    super(message);
  }
}

function cleanData<TData>(data: Partial<TData>): Partial<TData> {
  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(data).filter(([_, value]) => !!value)
  ) as Partial<TData>;
}

export function useForm<TData = object>({
  values,
  onValidate,
}: {
  values?: Partial<TData>;
  onValidate?: (data: Partial<TData>) => FormErrors<TData>;
}) {
  const [formData, setFormData] = useState<Partial<TData>>({});
  const [errors, setErrors] = useState<FormErrors<TData>>({});
  const [dirty, setDirty] = useState<(keyof TData)[]>([]);

  useEffect(() => {
    setFormData(cleanData(values || {}));
  }, [values]);

  const validate = () => {
    const cleanedData = cleanData(formData);
    const errors = onValidate?.(cleanedData) ?? {};
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const setError = (key: keyof FormErrors<TData>, error: string) => {
    setErrors((prev) => ({ ...prev, [key]: error }));
  };

  const clearErrors = () => {
    setErrors({});
  };

  const handleInput =
    (key: keyof TData) =>
    (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.currentTarget.value;
      setFormData((prev) => ({ ...prev, [key]: value }));
      setErrors((prev) => ({ ...prev, [key]: undefined }));
      if (!dirty.includes(key)) {
        setDirty((prev) => [...prev, key]);
      }
    };

  const handleBlur = (key: keyof TData) => () => {
    if (!dirty.includes(key)) {
      return;
    }

    const cleanedData = cleanData(formData);
    const errors: FormErrors<TData> = onValidate?.(cleanedData) ?? {};
    setErrors((prev) => ({ ...prev, [key]: errors[key] }));
  };

  const setValue = <K extends keyof TData>(key: K, value: TData[K]) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const reset = (key: keyof TData) => {
    setFormData((prev) => ({ ...prev, [key]: "" }));
    setErrors((prev) => ({ ...prev, [key]: undefined }));
    setDirty((prev) => prev.filter((k) => k !== key));
  };

  const register = (key: keyof TData) => ({
    onInput: handleInput(key),
    onBlur: handleBlur(key),
    error: errors[key],
    value: formData[key],
  });

  const errorBoundary =
    <
      TFn extends (...args: TArgs) => TResult,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      TArgs extends any[] = any[],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      TResult = any
    >(
      fn: TFn
    ) =>
    async (...args: Parameters<TFn>) => {
      try {
        return await fn(...args);
      } catch (e) {
        // TODO: [VA-12] Log error to Sentry
        if (process.env.NODE_ENV === "development") {
          console.error(e);
        }

        if (e instanceof UserError) {
          const userError = e;
          const field = userError.field || ["root"];
          setErrors((prev) => {
            return field.reduce(
              (acc, f) => ({
                ...acc,
                [f]: userError.message,
              }),
              prev
            );
          });
          return;
        }

        setErrors((prev) => ({
          ...prev,
          root: "An unknown error occurred. Please try again.",
        }));
      }
    };

  const handleSubmit = (cb: (data: TData) => Promise<void>) =>
    errorBoundary(async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const isValid = validate();
      if (!isValid) {
        return;
      }

      const cleanedData = cleanData(formData);
      return cb(cleanedData as TData);
    });

  return {
    formData,
    errors,

    setValue,
    reset,

    setError,
    clearErrors,

    validate,

    register,
    handleSubmit,
  };
}

export const validateYupSchema =
  <T,>(schema: yup.BaseSchema<T>) =>
  (data: Partial<T>) => {
    try {
      schema.validateSync(data, {
        abortEarly: false,
      });
      return {};
    } catch (e) {
      if (!(e instanceof yup.ValidationError)) {
        throw e;
      }

      return e.inner.reduce((acc, curr) => {
        if (curr.path === undefined) {
          return acc;
        }

        return {
          ...acc,
          [curr.path]: curr.message,
        };
      }, {} as FormErrors<T>);
    }
  };
