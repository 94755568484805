import { AuthLayout } from "@/components/layouts/auth";
import { Logo } from "@/components/logo";
import { H1, H2, Link, Or, P } from "@vetsie/ai-ui";
import LoginForm from "@vetsie/ai-ui/src/auth/login-form";
import Head from "next/head";
import { ReactElement } from "react";

function LoginPage() {
  return (
    <main className="flex flex-col gap-6">
      <Head>
        <title>Login | Vetsie.ai</title>
      </Head>
      <div className="flex flex-col gap-3">
        <Logo width={176} style="full" />
        <H1 size="md">Welcome to Vetsie.ai</H1>
        <P size="md">
          Sign in to start chatting with your AI veterinary assistant!
        </P>
      </div>
      <LoginForm />
      <Or />
      <P size="md" className="text-center">
        Don&rsquo;t have an account? <Link href="/signup">Sign up</Link>
      </P>
    </main>
  );
}

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return (
    <AuthLayout
      sidebar={
        <>
          <H2 size="lg" font="display" className="mb-4 !text-downriver-950">
            Your personalized AI-Powered veterinary assistant.
          </H2>
          <P size="lg">
            Introducing an AI-Powered data platform that enables veterinary
            professionals to research patient conditions, treatments, and
            medications swiftly and accurately.
          </P>
        </>
      }
    >
      {page}
    </AuthLayout>
  );
};

export default LoginPage;
