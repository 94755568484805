import { P } from "./text";

export function Or() {
  return (
    <P
      size="sm"
      className="flex justify-center items-center gap-4 font-semibold"
    >
      <span className="border-t border-black/10 dark:border-white/30 grow h-0"></span>
      or
      <span className="border-t border-black/10 dark:border-white/30 grow h-0"></span>
    </P>
  );
}
