import { PropsWithChildren, createContext, useContext, useState } from "react";

type Theme = "classic" | "modern";

const ThemeContext = createContext([
  "classic" as Theme,
  (_: Theme) => {},
] as const);

type ThemeProviderProps = PropsWithChildren<{
  defaultTheme: Theme;
}>;

export function ThemeProvider({
  defaultTheme = "classic",
  children,
}: ThemeProviderProps) {
  const theme = useState<Theme>(defaultTheme);

  return (
    <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
  );
}

export function useTheme() {
  return useContext(ThemeContext);
}
