import clsx from "clsx";
import React from "react";
import { fredoka } from "./fonts";
import { useTheme } from "./theme-provider";

export interface HeadingElementProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";
  font?: "display" | "sans";
}

export interface HeadingProps extends HeadingElementProps {
  tagName: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

export function Heading({
  tagName: Component,
  size,
  font = "sans",
  className,
  ...props
}: HeadingProps) {
  const [theme] = useTheme();

  return (
    <Component
      className={clsx(
        theme === "classic" && "text-black/50 dark:text-white/70",
        size === "xs" &&
          "text-xs font-semibold leading-[1.125rem] tracking-[0.16px]",
        size === "sm" &&
          "text-sm font-semibold leading-[1.375rem] tracking-normal",
        size === "md" && "text-xl leading-[1.625rem] tracking-normal",
        size === "lg" && "text-[1.75rem] leading-[2.25rem] tracking-normal",
        size === "xl" &&
          "text-[2rem] font-light leading-[2.5rem] tracking-normal",
        size === "2xl" &&
          "text-[2.625rem] font-light leading-[3.125rem] tracking-normal",
        size === "3xl" &&
          "text-[3.375rem] font-light leading-[4rem] tracking-normal",
        font === "display" && `${fredoka.variable} font-display`,
        className
      )}
      {...props}
    />
  );
}

export function H1(props: HeadingElementProps) {
  return <Heading tagName="h1" {...props} />;
}

export function H2(props: HeadingElementProps) {
  return <Heading tagName="h2" {...props} />;
}

export function H3(props: HeadingElementProps) {
  return <Heading tagName="h3" {...props} />;
}

export function H4(props: HeadingElementProps) {
  return <Heading tagName="h4" {...props} />;
}

export function H5(props: HeadingElementProps) {
  return <Heading tagName="h5" {...props} />;
}

export function H6(props: HeadingElementProps) {
  return <Heading tagName="h6" {...props} />;
}
