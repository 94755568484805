import { graphql } from "@/__generated__";
import { useUser } from "@vetsie/ai-auth";
import clsx from "clsx";
import { PropsWithChildren } from "react";

const QUERY = graphql(/* GraphQL */ `
  query SignupPage {
    me {
      id
    }
  }
`);

export type AuthLayoutProps = PropsWithChildren<{
  sidebar?: React.ReactNode;
}>;

export function AuthLayout({ sidebar, children }: AuthLayoutProps) {
  useUser(QUERY, {
    redirectTo: "/",
    redirectIfFound: true,
  });

  return (
    <>
      <main className="w-full lg:w-1/2 flex h-full">
        <div className="w-full p-12 md:px-[25%] my-auto">{children}</div>
      </main>
      <aside className="w-1/2 h-full p-12 top-0 right-0 lg:flex hidden items-center justify-center fixed">
        <div
          className={clsx(
            "w-full h-full px-7 flex items-center",
            "bg-[url('/images/intro-dog.webp')] bg-center bg-cover bg-no-repeat",
            "rounded-xl"
          )}
        >
          <div
            className={clsx(
              "px-9 py-[60px] rounded-[5px] mx-auto",
              "bg-gray-100/50 text-downriver-950 backdrop-blur-md rounded-md"
            )}
          >
            {sidebar}
          </div>
        </div>
      </aside>
    </>
  );
}
