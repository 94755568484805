import clsx from "clsx";
import NextLink from "next/link";
import React from "react";
import { useTheme } from "./theme-provider";

export interface LinkProps
  extends React.ComponentPropsWithoutRef<typeof NextLink> {}

export function Link({ className, ...props }: LinkProps) {
  const [theme] = useTheme();

  return (
    <NextLink
      {...props}
      className={clsx(
        "hover:underline",
        theme === "classic" &&
          clsx(
            "text-malibu-500 hover:text-malibu-400 focus-visible:text-malibu-400 active:text-malibu-600"
          ),
        theme === "modern" &&
          clsx(
            "text-lime-600 hover:text-lime-700 focus-visible:text-lime-700 active:text-lime-800",
            "dark:text-lime-200 dark:hover:text-lime-300 dark:focus-visible:text-lime-300 dark:active:text-lime-400"
          ),
        className
      )}
    />
  );
}
