import {
  Button,
  FieldError,
  Form,
  FormErrors,
  Input,
  Label,
  Link,
  Text,
  useForm,
} from "..";

import { useTrack } from "@vetsie/ai-analytics";
import { AuthError, useAuth } from "@vetsie/ai-auth";
import { useEffect } from "react";

export interface FormData {
  email: string;
  password: string;
}

interface LoginFormProps {
  onLoggedIn?: () => void;
}

export default function LoginForm({ onLoggedIn }: LoginFormProps) {
  const { login, token } = useAuth();
  const { trackUser } = useTrack();

  const { errors, setError, handleSubmit, register } = useForm<FormData>({
    onValidate: (formData: Partial<FormData>) => {
      const errors: FormErrors<FormData> = {};
      if (!formData.email) {
        errors.email = "Email is required";
      }
      if (!formData.password) {
        errors.password = "Password is required";
      }
      return errors;
    },
  });

  useEffect(() => {
    if (token) {
      onLoggedIn?.();
    }
  }, [token, onLoggedIn]);

  const handleLogin = async (formData: FormData) => {
    try {
      await login(formData.email, formData.password);
      trackUser("Signed In");
    } catch (error) {
      if (error instanceof AuthError) {
        if (error.code === "auth/missing-credentials") {
          setError("root", "Email and password are required.");
          return;
        }

        if (error.code === "auth/invalid-credentials") {
          setError("root", "Invalid email or password.");
          return;
        }

        if (error.code === "auth/too-many-requests") {
          setError(
            "root",
            "Too many failed login attempts. Please try again later, or try [Forgot password](/auth/forgot-password)."
          );
          return;
        }
      }

      throw error;
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleLogin)}>
      {errors.root && <FieldError error={errors.root} />}
      <div>
        <Label htmlFor="email">Email</Label>
        <Input
          {...register("email")}
          type="email"
          id="email"
          placeholder="Enter your email"
        />
        <FieldError error={errors.email} />
      </div>
      <div>
        <Label htmlFor="password">Password</Label>
        <Input
          {...register("password")}
          type="password"
          id="password"
          placeholder="Enter your password"
        />
        <FieldError error={errors.password} />
        <Text size="sm" className="mt-1">
          <Link href="/forgot-password">Forgot password?</Link>
        </Text>
      </div>
      <Button type="submit" className="w-full">
        Sign In
      </Button>
    </Form>
  );
}
