import clsx from "clsx";
import React from "react";
import { fredoka } from "./fonts";

export function getTextClasses({
  size = "md",
  font = "sans",
}: {
  size?: TextElementProps["size"];
  font?: TextElementProps["font"];
}) {
  return clsx(
    size === "sm" && "text-xs leading-4 tracking-[0.16px]",
    size === "md" && "text-sm leading-[1.125rem] tracking-[0.16px]",
    size === "lg" && "text-base leading-[1.375rem] tracking-normal",
    font === "display" && `${fredoka.variable} font-display`
  );
}

export interface TextElementProps extends React.ComponentPropsWithoutRef<"p"> {
  size?: "sm" | "md" | "lg";
  font?: "display" | "sans";
}

export interface TextProps extends TextElementProps {
  tagName?: "p";
}

export function Text({
  tagName: Component = "p",
  size,
  font = "sans",
  className,
  ...props
}: TextProps) {
  return (
    <Component
      className={clsx(getTextClasses({ size, font }), className)}
      {...props}
    />
  );
}

export function P(props: TextElementProps) {
  return <Text tagName="p" {...props} />;
}
